<template>
  <div class="page-parent-mine">
    <!-- 头部渐变背景 -->
    <div class="my-head">
      <div class="my-one">
        <div class="my-info">
          <img
            class="my-pic"
            width="60"
            height="60"
            :src="
              viewData.ProfilePicture ? viewData.ProfilePicture : defaultImg
            "
            @error="errorImg"
            @click.stop="openUpload"
            alt=""
          />
          <van-uploader
            v-model="accessoryList"
            :deletable="true"
            :preview-full-image="false"
            accept="image/*"
            :before-read="beforeRead"
            @click-preview="review"
            style="width: 0; height: 0"
          >
            <span id="upload_span"></span>
          </van-uploader>
          <van-uploader
            v-model="childAccessoryList"
            :deletable="true"
            :preview-full-image="false"
            accept="image/*"
            :before-read="childBeforeRead"
            @click-preview="review"
            style="width: 0; height: 0"
          >
            <span id="childUpload_span"></span>
          </van-uploader>
          <div class="my-des">
            <div class="my-name">{{ viewData.RealName }}</div>
            <div class="my-label">手机号码：{{ viewData.CellPhone }}</div>
          </div>
        </div>
        <div v-if="mailbox" @click="viewMailbox">
          <van-badge :content="mailbox">
            <van-icon name="chat-o" size="30px" />
          </van-badge>
        </div>
        <div v-else @click="viewMailbox">
          <van-badge>
            <van-icon name="chat-o" size="30px" />
          </van-badge>
        </div>
      </div>
    </div>
    <div class="my-page">
      <!-- 宝贝列表 -->
      <div v-if="userType === '3'" class="children-list">
        <div
          v-for="(item, i) in childsList"
          :key="i"
          class="children-card"
          @click.stop="openChild(item)"
        >
          <div class="img">
            <img
              width="60"
              height="60"
              :src="item.ProfilePicture ? item.ProfilePicture : defaultImg"
              @error="errorImg"
              alt=""
              @click.stop="editChildrenPic(item)"
            />
          </div>
          <div class="children-name">
            {{ item.ChildrenName }}
            <span v-if="item.IsOpen">默认显示</span>
            <span class="auditing" v-if="item.AuditState == 1">待审核</span>
            <span class="overrule" v-if="item.AuditState == 4">审核驳回</span>
          </div>
          <div class="children-class">
            {{ item.SchoolName }} / {{ item.ClassName }}
          </div>
          <i
            v-if="!item.IsOpen && item.AuditState == 3"
            class="del"
            @click.stop="delChild(item.ID)"
          ></i>
          <i
            v-if="item.AuditState == 4"
            class="edit"
            @click.stop="EditChild(item.ID)"
          ></i>
        </div>
        <div class="children-card">
          <div class="children-add" @click="addChildren">+</div>
          <div class="add-word">添加宝贝</div>
        </div>
      </div>
      <van-button type="primary" block color="#47AFA7" @click="change"
        >切换角色</van-button
      >
    </div>
    <!-- 菜单栏 -->
    <NavBar v-bind:active="3" />
  </div>
</template>

<script>
import defaultImg from "@/assets/icon/icon-my.png";
import NavBar from "@/components/NavBar";
import { Dialog, Button, Uploader, Toast, Icon, Badge } from "vant";
export default {
  components: {
    NavBar,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    [Toast.name]: Toast,
    [Icon.name]: Icon,
    [Badge.name]: Badge,
  },
  data() {
    return {
      defaultImg: defaultImg,
      userType: window.localStorage.getItem("UserType"),
      userInfo: JSON.parse(window.localStorage.getItem("userInfo")),

      childsList: [],
      viewData: {},
      accessoryList: [],
      mailbox: 0,
      childAccessoryList:[],
      childrenId:''
    };
  },
  mounted() {
    // 获取已绑定的宝贝
    this.getChilds();
    // 获取登录人信息
    this.getInfo();
    this.getMailbox();
  },
  methods: {
    getMailbox() {
      this.$axios.get("/api/ParentMailbox/GetPsersonal").then((res) => {
        this.mailbox = res.data.count;
      });
    },
    getInfo() {
      this.$axios
        .get("/api/User/Get", {
          id: this.userInfo.ID,
        })
        .then((res) => {
          this.viewData = res.data;
        });
    },
    getChilds() {
      this.$axios.get("/api/Parent/GetParentChildrenList").then((res) => {
        if (res.code === 200) {
          this.childsList = res.data.ChildrenList;
        } else {
          this.$toast.fail(res.msg || "操作失败");
        }
      });
    },
    openChild(children) {
      if (children.AuditState != 3) {
        this.$toast.fail("无法切换未通过的宝贝信息");
        return;
      }
      Dialog.confirm({
        title: "提示",
        message: "确定要切换到该宝贝吗？",
      })
        .then(() => {
          // on confirm
          this.$axios
            .post("/api/Parent/ChangeChildren", {
              ID: children.ID,
              IsOpen: true,
            })
            .then((res) => {
              if (res.code === 200) {
                this.$toast.success("切换成功");
                // 更改schoolID
                window.localStorage.setItem(
                  "childrenInfo",
                  JSON.stringify(children)
                );
                
                let user = JSON.parse(window.localStorage.getItem('userInfo'));
                user.OpenChildren = children;
                window.localStorage.setItem('userInfo',JSON.stringify(user));
                window.localStorage.setItem("SchoolID", children.SchoolID);
                location.reload();
                this.getChilds();
              } else {
                this.$toast.fail(res.msg || "操作失败");
              }
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    delChild(ID) {
      Dialog.confirm({
        title: "提示",
        message: "确定要删除该宝贝吗？",
      })
        .then(() => {
          // on confirm
          this.$axios
            .post("/api/Parent/Delete", {
              ID: ID,
            })
            .then((res) => {
              if (res.code === 200) {
                this.$toast.success("删除成功");
                location.reload();
                this.getChilds();
              } else {
                this.$toast.fail(res.msg || "操作失败");
              }
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    addChildren() {
      this.$router.push({
        path: "AddChild",
        query: {
          id: this.userInfo.ID,
        },
      });
    },
    change() {
      const obj = {
        OpenID: this.userInfo.OpenID,
        headUrl: this.userInfo.HeadUrl,
      };
      window.localStorage.setItem("wxuserInfo", JSON.stringify(obj));
      this.$router.replace("ChangeRole");
    },
    errorImg() {
      const img = event.srcElement;
      img.src = this.defaultImg;
      img.onerror = null;
    },
    beforeRead(file) {
      if (file.size / 1024 > 1025) {
        this.$compress.photoCompress(
          file,
          {
            quality: 0.2,
          },
          (base64Codes) => {
            file = this.$compress.dataURLtoFile(base64Codes, file.name);
            Toast.loading({
              message: "加载中...",
              duration: 0,
            });
            let formData = new FormData();
            formData.append("LastFile", file);
            formData.append("FileName", file.name);
            this.$axios.upload("/api/File/UploadFile", formData).then((res) => {
              Toast.clear();
              if (res.code === 200) {
                // this.accessoryList.push({
                //   name: file.name,
                //   osskey: res.data.osskey,
                //   uploadUrl: res.data.uploadUrl,
                //   url: res.data.url
                // })

                // 提交头像
                this.editPic(res.data);
                return true;
              } else {
                this.$toast.fail(res.msg || "操作失败");
                return false;
              }
            });
          }
        );
      } else {
        Toast.loading({
          message: "加载中...",
          duration: 0,
        });
        let formData = new FormData();
        formData.append("LastFile", file);
        formData.append("FileName", file.name);
        this.$axios.upload("/api/File/UploadFile", formData).then((res) => {
          Toast.clear();
          if (res.code === 200) {
            // this.accessoryList.push({
            //   name: file.name,
            //   osskey: res.data.osskey,
            //   uploadUrl: res.data.uploadUrl,
            //   url: res.data.url
            // })

            // 提交头像
            this.editPic(res.data);
            return true;
          } else {
            this.$toast.fail(res.msg || "操作失败");
            return false;
          }
        });
      }
    },
    review(file) {
      if (file.url) {
        window.open(file.url);
      }
    },
    editPic(params) {
      this.$axios
        .post("/api/User/EditUserUrlForApp", {
          UserUrlList: [params],
          ID: this.userInfo.ID,
        })
        .then((res) => {
          this.$toast.success(res.msg || "操作成功");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    },
    openUpload() {
      this.$jq("#upload_span").siblings("input").click();
    },
    viewMailbox() {
      this.$router.push("MailboxForParentList");
    },
    EditChild(id) {
      this.$router.push({
        path: "/RegisterEdit",
        query: {
          ID: id,
        },
      });
    },
    editChildrenPic(item){
      this.childrenId = item.ID
      this.$jq("#childUpload_span").siblings("input").click();
    },
    childBeforeRead(file) {
      if (file.size / 1024 > 1025) {
        this.$compress.photoCompress(
          file,
          {
            quality: 0.2,
          },
          (base64Codes) => {
            file = this.$compress.dataURLtoFile(base64Codes, file.name);
            Toast.loading({
              message: "加载中...",
              duration: 0,
            });
            let formData = new FormData();
            formData.append("LastFile", file);
            formData.append("FileName", file.name);
            this.$axios.upload("/api/File/UploadFile", formData).then((res) => {
              Toast.clear();
              if (res.code === 200) {
                // 提交头像
                this.editChildPic(res.data);
                return true;
              } else {
                this.$toast.fail(res.msg || "操作失败");
                return false;
              }
            });
          }
        );
      } else {
        Toast.loading({
          message: "加载中...",
          duration: 0,
        });
        let formData = new FormData();
        formData.append("LastFile", file);
        formData.append("FileName", file.name);
        this.$axios.upload("/api/File/UploadFile", formData).then((res) => {
          Toast.clear();
          if (res.code === 200) {
            // 提交头像
            this.editChildPic(res.data);
            return true;
          } else {
            this.$toast.fail(res.msg || "操作失败");
            return false;
          }
        });
      }
    },
    editChildPic(params) {
      this.$axios
        .post("/api/Children/EditChildrenPic", {
          uploadList: [params],
          ID: this.childrenId,
        })
        .then((res) => {
          this.$toast.success(res.msg || "操作成功");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
